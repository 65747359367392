export const tripInfosIntlIds = {
  changeOrAssignTripRiderModal: {
    delivery: {
      title: 'tripInfosIntlIds.changeOrAssignTripRiderModal.delivery.title',
    },
    trip: {
      title: 'tripInfosIntlIds.changeOrAssignTripRiderModal.trip.title',
    },
    selectRider: {
      placeholder:
        'tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder',
      option: {
        noRider:
          'tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider.option.noRider',
      },
    },
    button: {
      cancel: {
        label:
          'tripInfosIntlIds.changeOrAssignTripRiderModal.button.cancel.label',
      },
      assign: {
        label:
          'tripInfosIntlIds.changeOrAssignTripRiderModal.button.assign.label',
      },
      sendOffer: {
        label:
          'tripInfosIntlIds.changeOrAssignTripRiderModal.button.sendOffer.label',
      },
    },
  },
  tripActionButton: {
    label: 'tripInfosIntlIds.tripActionButton.label',
  },
  tripDetails: {
    id: {
      label: 'tripInfosIntlIds.tripDetails.id.label',
    },
    assignmentMode: {
      label: 'tripInfosIntlIds.tripInfos.assignmentMode.label',
      status: {
        noRiderAssigned:
          'tripInfosIntlIds.tripInfos.assignmentMode.status.noRiderAssigned',
        findingRider:
          'tripInfosIntlIds.tripInfos.assignmentMode.status.findingRider',
        assigned: 'tripInfosIntlIds.tripInfos.assignmentMode.status.assigned',
      },
    },
  },
  tripRiderInfos: {
    avatar: {
      title: 'tripInfosIntlIds.tripRiderInfos.avatar.title',
    },
    hub: 'tripInfosIntlIds.tripRiderInfos.hub',
    modal: 'tripInfosIntlIds.tripRiderInfos.modal',
    logisticOperatorType:
      'tripInfosIntlIds.tripRiderInfos.logisticOperatorType',
  },
};
