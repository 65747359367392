import { ApolloClient, InMemoryCache } from '@apollo/client';
import { envs } from '@config';

import { mainMiddleware } from './middlewares';

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Trip: {
        fields: {
          points: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link: mainMiddleware,
  name: 'Bff client',
  version: '0-beta',
  connectToDevTools: envs.isDevelopment,
});

export default client;
