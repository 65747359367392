import { deliverySearchInputIntlIds } from './delivery-search-input.ids';

export const deliverySearchInputLangPtBr = {
  [deliverySearchInputIntlIds.type.searchBy]: `{searchBy, select,
    orderId {ID Pedido}
    riderId {ID Rider}
    other {}
  }`,
  [deliverySearchInputIntlIds.type.orderId]: 'ID Pedido',
  [deliverySearchInputIntlIds.type.riderId]: 'ID Rider',

  [deliverySearchInputIntlIds.input.label]: 'Procure uma Entrega',
  [deliverySearchInputIntlIds.input.placeholder]: 'Buscar',

  [deliverySearchInputIntlIds.submitButton.label]: 'Buscar',
};
