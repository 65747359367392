import { tripsIntlIds } from './trips.ids';

export const tripsLangEsMx = {
  [tripsIntlIds.seo.pages.tripsList.title]: 'Lista de Viajes',
  [tripsIntlIds.seo.pages.tripsList.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [tripsIntlIds.seo.pages.tripChooseDeliveries.title]: 'Crear Viaje',
  [tripsIntlIds.seo.pages.tripChooseDeliveries.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [tripsIntlIds.seo.pages.tripCreate.title]: 'Crear Viaje',
  [tripsIntlIds.seo.pages.tripCreate.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [tripsIntlIds.seo.pages.tripEdit.title]: 'Editar Viaje',
  [tripsIntlIds.seo.pages.tripEdit.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [tripsIntlIds.seo.pages.tripDetails.title]: 'Viaje {tripId}',
  [tripsIntlIds.seo.pages.tripDetails.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [tripsIntlIds.tripsList.header.title]: 'Viajes',
  [tripsIntlIds.tripsList.header.createButton.label]: 'Crear viaje',

  [tripsIntlIds.tripContentHeader.navigationInfo.trips]: 'Viajes',

  [tripsIntlIds.tripStatus.pending]: 'Pendiente',
  [tripsIntlIds.tripStatus.started]: 'Iniciada',
  [tripsIntlIds.tripStatus.completed]: 'Concluída',
  [tripsIntlIds.tripStatus.canceled]: 'Cancelada',
  [tripsIntlIds.noTripsFound.label]: `Aún no hay viajes {trip_status, select,
      pending {por iniciar}
      started {iniciadas}
      completed {completadas}
      canceled {canceladas}
      voided {}
      unknown {}
      other {creadas}
  }`,

  [tripsIntlIds.tripsList.table.cell.header.tripId]: 'ID de viaje',
  [tripsIntlIds.tripsList.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.tripsList.table.cell.header.deliveryDate]: 'Fecha de entrega',
  [tripsIntlIds.tripsList.table.cell.header.numberOfDeliveries]:
    'Cant. de entregas',
  [tripsIntlIds.tripsList.table.cell.header.tripStatus]: 'Estado del viaje',
  [tripsIntlIds.tripsList.table.cell.header.assignedRider]:
    'Repartidor asignado',

  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries
    .total]: `{total, plural,
      =0 {# entregas}
      one {# entrega}
      other {# entregas}
    }`,
  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries.byStatus]:
    '{total_processing} Procesando, {total_ready} Lista y {total_canceled} Cancelada',

  [tripsIntlIds.tripsList.header.tabs.all.label]: 'Todas',
  [tripsIntlIds.tripsList.header.tabs.pending.label]: 'Pendientes',
  [tripsIntlIds.tripsList.header.tabs.started.label]: 'Iniciadas',
  [tripsIntlIds.tripsList.header.tabs.completed.label]: 'Concluidas',
  [tripsIntlIds.tripsList.header.tabs.canceled.label]: 'Cancelados',

  [tripsIntlIds.tripsList.toasts.onSuccess.title]:
    '¡Viaje creado exitosamente!',
  [tripsIntlIds.tripsList.toasts.onSuccess.description]:
    'Consulte los detalles en la lista de viajes',

  [tripsIntlIds.tripsList.toasts.onWarning.title]: 'Viaje {tripId} deshecho',
  [tripsIntlIds.tripsList.toasts.onWarning.description]:
    'Las entregas se ofrecerán por separado de forma automática',

  [tripsIntlIds.createTrip.selectHubAndDateDrawer.title]: 'Crear viaje',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.title]:
    'Seleccione el hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .description]: 'Seleccione para qué hub se creará el viaje',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.label]:
    'Hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .placeholder]: 'Ej: SAO001 - Pinheiros',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .noScheduledOrders]:
    'No hay entregas programadas para este hub, elija otro o intente nuevamente más tarde',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.title]:
    'Seleccione la fecha de entrega',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .description]:
    'Todos los pedidos programados para entrega en la fecha seleccionada estarán disponibles para el viaje',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.label]:
    'Fecha de entrega',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .placeholder]: 'Ej: Lunes, 20/06',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer
    .chooseDeliveriesButton.label]: 'Elegir pedidos',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer.cancelButton
    .label]: 'Cancelar',

  [tripsIntlIds.createTrip.header.title]: 'Crear nuevo viaje: {hubAlias}',
  [tripsIntlIds.createTrip.header.navigationInfo.label]:
    'Selección de hub / Selección de pedidos / Confirmación',
  [tripsIntlIds.createTrip.header.confirmCreateButton.label]: 'Crear viaje',
  [tripsIntlIds.createTrip.header.cancelCreateButton.label]: 'Cancelar',

  [tripsIntlIds.editTrip.header.title]: 'Viaje: {tripId}',
  [tripsIntlIds.editTrip.header.navigationInfo.label]:
    'Detalles del viaje / Editar viaje',
  [tripsIntlIds.editTrip.header.updateChosenDeliveriesButton
    .label]: `Actualizar {deliveriesQuantity, plural,
      =0 {pedidos}
      one {# pedido}
      other {# pedidos}
    }`,
  [tripsIntlIds.editTrip.header.cancelUpdateChosenDeliveriesButton.label]:
    'Cancelar',
  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirmar edición',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancelar edición',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Deshacer viaje',

  [tripsIntlIds.chooseDeliveries.header.title]: 'Crear nuevo viaje: {hubAlias}',
  [tripsIntlIds.chooseDeliveries.header.navigationInfo.label]:
    'Selección de hub / Selección de pedidos',
  [tripsIntlIds.chooseDeliveries.header.addsDeliveriesToTripButton
    .label]: `Agregar {deliveriesQuantity, plural,
      =0 {pedidos}
      one {# pedido}
      other {# pedidos}
    }`,
  [tripsIntlIds.chooseDeliveries.header.cancelAddDeliveriesToTripButton.label]:
    'Cancelar',
  [tripsIntlIds.chooseDeliveries.table.cell.header.orderId]: 'ID Pedido',
  [tripsIntlIds.chooseDeliveries.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.chooseDeliveries.table.cell.header.addressFormatted]:
    'Dirección',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryDate]:
    'Fecha Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryEta]: 'ETA Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryPeriod]:
    'Período Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryAssortmentType]:
    'Tipo Pedido',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.copyAddress
    .label]: 'Copiar dirección',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.addressCopied
    .label]: '¡Dirección copiada!',

  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.label]:
    'Aún no hay pedidos programados en el hub {hubAlias}',
  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.searchEmptyState
    .orderId.label]:
    '¡Ups! No encontramos ningún ID de Pedido en el hub {hubAlias} con {orderId}',

  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirmar edición',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancelar edición',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Deshacer viaje',

  [tripsIntlIds.tripDetails.header.title]: 'Viaje: {tripId}',
  [tripsIntlIds.tripDetails.header.navigationInfo.label]: 'Detalles del viaje',
  [tripsIntlIds.tripDetails.header.editTripButton.label]: 'Editar viaje',
  [tripsIntlIds.tripDetails.header.editTripButton.isDisabled.label]:
    'No es posible editar viajes que ya han comenzado',

  [tripsIntlIds.editTrip.tripAlreadyStarted.title]:
    '¡Este viaje ya ha comenzado!',
  [tripsIntlIds.editTrip.tripAlreadyStarted.description]:
    'Como la viaje ya ha comenzado, todas las ediciones realizadas no podrán ser guardadas.',
  [tripsIntlIds.editTrip.tripAlreadyStarted.gotItButton.label]: 'Entendido',

  [tripsIntlIds.tripDetails.chosenDelivery.orderId.label]: 'Pedido: {orderId}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryEta.label]:
    'Período de entrega: {etaMin} - {etaMax}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryStatus.label]: 'Status:',
  [tripsIntlIds.tripDetails.chosenDelivery.dragDeliveryButton.label]:
    'Arrastrar entrega',
  [tripsIntlIds.tripDetails.chosenDelivery.removeDeliveryButton.label]:
    'Eliminar entrega',

  [tripsIntlIds.tripDetails.tripSummaryDetails.tripDate.label]:
    'Fecha del viaje',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity.label]:
    'Pedidos agregados',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity
    .value]: `{addedDeliveriesQuantity, plural,
      =0 {# pedidos}
      one {# pedido}
      other {# pedidos}
    }`,
  [tripsIntlIds.tripDetails.tripSummaryDetails.addsMoreDeliveriesToTripButton
    .label]: '+ Agregar más pedidos',
  [tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title]:
    '¡El viaje se editó exitosamente!',

  [tripsIntlIds.onTripActionModal.header.title]: `{type, select,
    confirmCreate {¿Confirmar la creación del viaje?}
    cancelCreate {¿Desea cancelar la creación del viaje?}
    confirmEdit {¿Confirma las ediciones hechas en el viaje?}
    cancelEdit {¿Estás seguro de irte?}
    confirmRemoveDelivery {¿Desea eliminar el pedido del viaje?}
    confirmUndoTrip {¿Desea deshacer todo el viaje?}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.header.closeModalButton.label]:
    'Fechar modal',

  [tripsIntlIds.onTripActionModal.body.content]: `{type, select,
    confirmCreate {Después de confirmar, el viaje será creado.\n\nSi no hay un repartidor asignado, deberá asignarlo manualmente cuando haya repartidores disponibles.}
    cancelCreate {Al cancelar, todos los cambios se perderán y necesitará rehacerlos desde el principio.}
    confirmEdit {Estos cambios serán permanentes. Le aconsejamos que los revise antes de confirmar.}
    cancelEdit {Sus cambios no se guardaron y deberá repetirlos desde el principio.}
    confirmRemoveDelivery {Una vez que estén disponibles para la entrega, los pedidos eliminados se ofrecerán automáticamente a los Riders.}
    confirmUndoTrip {Después de deshacer el viaje, los pedidos de este viaje serán eliminados y cada uno se ofrecerá automáticamente por separado a los Riders cuando estén disponibles para la entrega.\n\nAconsejamos que revise antes de confirmar.}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onSubmitButton.label]: `{type, select,
    confirmCreate {Crear viaje}
    cancelCreate {Cancelar creación}
    confirmEdit {Confirmar edición}
    cancelEdit {Quédate y continuar}
    confirmRemoveDelivery {Eliminar pedido}
    confirmUndoTrip {Deshacer viaje}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onCancelButton.label]: `{type, select,
    confirmCreate {Cancelar}
    cancelCreate {Volver}
    confirmEdit {Cancelar}
    cancelEdit {Abandonar}
    confirmRemoveDelivery {Volver}
    confirmUndoTrip {Cancelar}
    other {}}
  `,

  [tripsIntlIds.tripPlanningDetails.hubAlias.label]:
    'Hub: <highlight>{hubAlias}</highlight>',
  [tripsIntlIds.tripPlanningDetails.plannedTo.label]:
    'Fecha del viaje: <highlight>{plannedTo}</highlight>',
  [tripsIntlIds.tripPlanningDetails.status.label]: 'Estado del viaje:',
};
