export const modalsIntlIds = {
  onActionModal: {
    header: {
      title: 'modalsIntlIds.onActionModal.header.title',
      closeModalButton: {
        label: 'modalsIntlIds.onActionModal.header.closeModalButton.label',
      },
    },

    body: {
      content: 'modalsIntlIds.onActionModal.body.content',
    },

    footer: {
      onSubmitButton: {
        label: 'modalsIntlIds.onActionModal.footer.onSubmitButton.label',
      },
      onCancelButton: {
        label: 'modalsIntlIds.onActionModal.footer.onCancelButton.label',
      },
    },
  },
};
