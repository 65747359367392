import { FormErrorMessage as ChakraFormErrorMessage } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { FormErrorMessageProps } from './form-error-message.types';

export default forwardRef(function FormErrorMessage(
  props: FormErrorMessageProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraFormErrorMessage {...props} ref={ref} m={0} />;
});
