import { deliverySearchInputIntlIds } from './delivery-search-input.ids';

export const deliverySearchInputLangEnUs = {
  [deliverySearchInputIntlIds.type.searchBy]: `{searchBy, select,
    orderId {Order ID}
    riderId {Rider ID}
    other {}
  }`,
  [deliverySearchInputIntlIds.type.orderId]: 'Order ID',
  [deliverySearchInputIntlIds.type.riderId]: 'Rider ID',

  [deliverySearchInputIntlIds.input.label]: 'Search',
  [deliverySearchInputIntlIds.input.placeholder]: 'Search',

  [deliverySearchInputIntlIds.submitButton.label]: 'Search',
};
