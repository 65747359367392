import { shiftsIntlIds } from './shifts.ids';

export const shiftsLangEnUs = {
  [shiftsIntlIds.seo.pages.shiftsList.title]: 'Shifts list',
  [shiftsIntlIds.seo.pages.shiftsList.description]:
    'Web application for Rider management and last mile Deliveries',

  [shiftsIntlIds.shiftsList.header.title]: 'Shifts',
  [shiftsIntlIds.shiftsList.header.createButton.label]: 'Create shift',

  [shiftsIntlIds.shiftsList.header.tabs.all.label]: 'All',

  [shiftsIntlIds.shiftsList.table.cell.header.id]: 'Shift ID',
  [shiftsIntlIds.shiftsList.table.cell.header.name]: 'Shift',
  [shiftsIntlIds.shiftsList.table.cell.header.startTime]: 'Start time',
  [shiftsIntlIds.shiftsList.table.cell.header.endTime]: 'End time',
  [shiftsIntlIds.shiftsList.table.cell.header.duration]: 'Duration',
  [shiftsIntlIds.shiftsList.table.cell.header.createdAt]: 'Created at',

  [shiftsIntlIds.shiftsList.table.noShiftsFound.label]: 'No active shifts',

  [shiftsIntlIds.shiftsList.toasts.onSuccess.title]:
    'Shift created successfully!',
  [shiftsIntlIds.shiftsList.toasts.onSuccess.description]:
    'Now just create vacancies and assign them to this shift',

  [shiftsIntlIds.createShift.drawer.title]: 'Create shift',
  [shiftsIntlIds.createShift.drawer.closeDrawerButton.label]: 'Close drawer',
  [shiftsIntlIds.createShift.drawer.content.header.title]: 'New shift',
  [shiftsIntlIds.createShift.drawer.content.header.description]:
    'Create a new shift at a time by filling in the details below. It can be used when creating new vacancies.',

  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.icon.title]:
    'Calendar icon',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.title]:
    'Define shift characteristics',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.description]:
    'Define the name that will be used to assign vacancies internally. Riders will not see this name.',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .label]: 'Shift name',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .placeholder]: 'E.g.: M1, T1, N1',
  [shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input
    .errors.nameAlreadyInUse]: 'There is already a shift with this name.',

  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.icon.title]:
    'Clock icon',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.title]:
    'Define the shift period',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.description]:
    'Indicate the start and end time of the shift. Remember that this is the period in which Riders will choose reservations.',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftStartTime.input
    .label]: 'Start time',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .label]: 'End time',
  [shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input
    .errors.invalidPeriod]: `{type, select,
      PERIOD_ALREADY_IN_USE {This shift already exists.}
      other {A shift must last a minimum of 30 minutes.}
    }`,

  [shiftsIntlIds.createShift.drawer.content.footer.confirmCreateShiftButton
    .label]: 'Create shift',
  [shiftsIntlIds.createShift.drawer.content.footer.cancelCreateShiftButton
    .label]: 'Cancel',
};
