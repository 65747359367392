import { FormControl as ChakraFormControl } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { FormControlProps } from './form-control.types';

export default forwardRef(function FormControl(
  props: FormControlProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraFormControl {...props} ref={ref} />;
});
