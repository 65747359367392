import { MenuGroup as ChakraMenuGroup } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { MenuGroupProps } from './menu-group.types';

export default forwardRef(function MenuGroup(
  props: MenuGroupProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return (
    <ChakraMenuGroup
      {...props}
      mx={3}
      fontSize="xs"
      fontWeight={400}
      textTransform="uppercase"
      letterSpacing={0.5}
      color="gray.600"
      ref={ref}
    />
  );
});
