import { manualOrAutomaticRiderAssignmentIntlIds } from './manual-or-automatic-rider-assignment.ids';

export const manualOrAutomaticRiderAssignmentLangEsMx = {
  [manualOrAutomaticRiderAssignmentIntlIds.mode
    .manualOrAutomatic]: `{trip_mode, select,
      manual {Manual}
      automatic {Automática}
      other {}
    }`,

  [manualOrAutomaticRiderAssignmentIntlIds.status.noRiderAssigned]:
    'Sin Rider asignado',
  [manualOrAutomaticRiderAssignmentIntlIds.status.findingRider]: 'Ofreciendo',
  [manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned]:
    '{rider_name}',
};
