import { modalsIntlIds } from './modals.ids';

export const modalsLangEnUs = {
  [modalsIntlIds.onActionModal.header.title]: `{type, select,
    confirm {Are you sure of that?}
    cancel {Are you sure of leaving?}
    other {}}
  `,

  [modalsIntlIds.onActionModal.header.closeModalButton.label]: 'Close modal',

  [modalsIntlIds.onActionModal.body.content]: `{type, select,
    confirm {If you confirm this action, it cannot be canceled. We advise you to revise before saving.}
    cancel {Your changes were not saved and you will need to repeat them from the beginning.}
    other {}}
  `,

  [modalsIntlIds.onActionModal.footer.onSubmitButton.label]: `{type, select,
    confirm {Save and continue}
    cancel {Stay and continue}
    other {}}
  `,
  [modalsIntlIds.onActionModal.footer.onCancelButton.label]: `{type, select,
    confirm {Cancel}
    cancel {Leave}
    other {}}
  `,
};
