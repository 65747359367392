import { Text } from '@common/ui-components';
import { ReactElement, ReactNode } from 'react';

export default function createHighlightTextWrapper(
  str: ReactNode,
  color = 'gray.700'
): ReactElement {
  return (
    <Text as="strong" color={color}>
      {str}
    </Text>
  );
}
