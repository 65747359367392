import config from './config';

export const baseTheme = {
  config,
  components: {
    Drawer: {
      parts: ['dialog', 'header', 'body'],
      variants: {
        primary: {
          dialog: {
            maxW: '43.75rem',
          },
        },
      },
    },
  },
};
