import { tripsIntlIds } from './trips.ids';

export const tripsLangPtBr = {
  [tripsIntlIds.seo.pages.tripsList.title]: 'Lista de Viagens',
  [tripsIntlIds.seo.pages.tripsList.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [tripsIntlIds.seo.pages.tripChooseDeliveries.title]: 'Criar Viagem',
  [tripsIntlIds.seo.pages.tripChooseDeliveries.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [tripsIntlIds.seo.pages.tripCreate.title]: 'Criar Viagem',
  [tripsIntlIds.seo.pages.tripCreate.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [tripsIntlIds.seo.pages.tripEdit.title]: 'Editar Viagem',
  [tripsIntlIds.seo.pages.tripEdit.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [tripsIntlIds.seo.pages.tripDetails.title]: 'Viagem {tripId}',
  [tripsIntlIds.seo.pages.tripDetails.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [tripsIntlIds.tripsList.header.title]: 'Viagens',
  [tripsIntlIds.tripsList.header.createButton.label]: 'Criar viagem',

  [tripsIntlIds.tripContentHeader.navigationInfo.trips]: 'Viagens',

  [tripsIntlIds.tripStatus.pending]: 'Pendente',
  [tripsIntlIds.tripStatus.started]: 'Iniciada',
  [tripsIntlIds.tripStatus.completed]: 'Concluída',
  [tripsIntlIds.tripStatus.canceled]: 'Cancelada',
  [tripsIntlIds.noTripsFound.label]: `Ainda não há viagens {trip_status, select,
      pending {pendentes de iniciar}
      started {iniciadas}
      completed {concluídas}
      canceled {canceladas}
      voided {}
      unknown {}
      other {criadas}
  }`,

  [tripsIntlIds.tripsList.table.cell.header.tripId]: 'ID viagem',
  [tripsIntlIds.tripsList.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.tripsList.table.cell.header.deliveryDate]: 'Data entrega',
  [tripsIntlIds.tripsList.table.cell.header.numberOfDeliveries]:
    'Quant. de entregas',
  [tripsIntlIds.tripsList.table.cell.header.tripStatus]: 'Status da viagem',
  [tripsIntlIds.tripsList.table.cell.header.assignedRider]: 'Rider atribuído',

  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries
    .total]: `{total, plural,
    =0 {# entregas}
    one {# entrega}
    other {# entregas}
  }`,
  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries.byStatus]:
    '{total_processing} Processando, {total_ready} Pronta e {total_canceled} Cancelada',

  [tripsIntlIds.tripsList.header.tabs.all.label]: 'Todas',
  [tripsIntlIds.tripsList.header.tabs.pending.label]: 'Pendentes',
  [tripsIntlIds.tripsList.header.tabs.started.label]: 'Iniciadas',
  [tripsIntlIds.tripsList.header.tabs.completed.label]: 'Concluídas',
  [tripsIntlIds.tripsList.header.tabs.canceled.label]: 'Canceladas',

  [tripsIntlIds.tripsList.toasts.onSuccess.title]: 'Viagem criada com sucesso!',
  [tripsIntlIds.tripsList.toasts.onSuccess.description]:
    'Confira os detalhes na lista de viagens',

  [tripsIntlIds.tripsList.toasts.onWarning.title]: 'Viagem {tripId} desfeita',
  [tripsIntlIds.tripsList.toasts.onWarning.description]:
    'Entregas serão ofertadas separadamente de forma automática',

  [tripsIntlIds.createTrip.selectHubAndDateDrawer.title]: 'Criar viagem',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.title]:
    'Selecione o hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .description]: 'Selecione para qual hub a viagem será criada',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.label]:
    'Hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .placeholder]: 'Ex: SAO001 - Pinheiros',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .noScheduledOrders]:
    'Não há entregas agendadas para este hub, escolha outro ou tente novamente mais tarde',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.title]:
    'Selecione a data de entrega',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .description]:
    'Todos os pedidos agendados para entrega na data selecionada serão disponibilizados para a viagem',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.label]:
    'Data de entrega',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .placeholder]: 'Ex: Segunda-feira, 20/06',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer
    .chooseDeliveriesButton.label]: 'Escolher pedidos',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer.cancelButton
    .label]: 'Cancelar',

  [tripsIntlIds.createTrip.header.title]: 'Criar nova viagem: {hubAlias}',
  [tripsIntlIds.createTrip.header.navigationInfo.label]:
    'Seleção de hub / Seleção de pedidos / Confirmar',
  [tripsIntlIds.createTrip.header.confirmCreateButton.label]: 'Criar viagem',
  [tripsIntlIds.createTrip.header.cancelCreateButton.label]: 'Cancelar',

  [tripsIntlIds.editTrip.header.title]: 'Viagem: {tripId}',
  [tripsIntlIds.editTrip.header.navigationInfo.label]:
    'Detalhes da viagem / Editar viagem',
  [tripsIntlIds.editTrip.header.updateChosenDeliveriesButton
    .label]: `Atualizar {deliveriesQuantity, plural,
    =0 {pedidos}
    one {# pedido}
    other {# pedidos}
  }`,
  [tripsIntlIds.editTrip.header.cancelUpdateChosenDeliveriesButton.label]:
    'Cancelar',
  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirmar edição',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancelar edição',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Desfazer viagem',

  [tripsIntlIds.chooseDeliveries.header.title]: 'Criar nova viagem: {hubAlias}',
  [tripsIntlIds.chooseDeliveries.header.navigationInfo.label]:
    'Seleção de hub / Seleção de pedidos',
  [tripsIntlIds.chooseDeliveries.header.addsDeliveriesToTripButton
    .label]: `Adicionar {deliveriesQuantity, plural,
    =0 {pedidos}
    one {# pedido}
    other {# pedidos}
  }`,
  [tripsIntlIds.chooseDeliveries.header.cancelAddDeliveriesToTripButton.label]:
    'Cancelar',
  [tripsIntlIds.chooseDeliveries.table.cell.header.orderId]: 'ID Pedido',
  [tripsIntlIds.chooseDeliveries.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.chooseDeliveries.table.cell.header.addressFormatted]:
    'Endereço',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryDate]:
    'Data Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryEta]: 'ETA Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryPeriod]:
    'Período Entrega',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryAssortmentType]:
    'Tipo Pedido',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.copyAddress
    .label]: 'Copiar endereço',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.addressCopied
    .label]: 'Endereço copiado!',

  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.label]:
    'Ainda não há pedidos agendados no hub {hubAlias}',
  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.searchEmptyState
    .orderId.label]:
    'Ops! Não encontramos nenhum ID de Pedido no hub {hubAlias} com {orderId}',

  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirmar edição',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancelar edição',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Desfazer viagem',

  [tripsIntlIds.tripDetails.header.title]: 'Viagem: {tripId}',
  [tripsIntlIds.tripDetails.header.navigationInfo.label]: 'Detalhes da viagem',
  [tripsIntlIds.tripDetails.header.editTripButton.label]: 'Editar viagem',
  [tripsIntlIds.tripDetails.header.editTripButton.isDisabled.label]:
    'Não é possível editar viagens que já iniciaram',

  [tripsIntlIds.editTrip.tripAlreadyStarted.title]:
    'Essa viagem já foi iniciada!',
  [tripsIntlIds.editTrip.tripAlreadyStarted.description]:
    'Como a viagem já foi iniciada, todas as edições realizadas não poderão ser salvas.',
  [tripsIntlIds.editTrip.tripAlreadyStarted.gotItButton.label]: 'Entendi',

  [tripsIntlIds.tripDetails.chosenDelivery.orderId.label]: 'Pedido: {orderId}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryEta.label]:
    'Período da entrega: {etaMin} - {etaMax}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryStatus.label]: 'Status:',
  [tripsIntlIds.tripDetails.chosenDelivery.dragDeliveryButton.label]:
    'Arrastar entrega',
  [tripsIntlIds.tripDetails.chosenDelivery.removeDeliveryButton.label]:
    'Remover entrega',

  [tripsIntlIds.tripDetails.tripSummaryDetails.tripDate.label]:
    'Data da viagem',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity.label]:
    'Pedidos adicionados',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity
    .value]: `{addedDeliveriesQuantity, plural,
    =0 {# pedidos}
    one {# pedido}
    other {# pedidos}
  }`,
  [tripsIntlIds.tripDetails.tripSummaryDetails.addsMoreDeliveriesToTripButton
    .label]: '+ Adicionar mais pedidos',
  [tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title]:
    'Viagem editada com sucesso!',

  [tripsIntlIds.onTripActionModal.header.title]: `{type, select,
    confirmCreate {Confirmar a criação da viagem?}
    cancelCreate {Deseja cancelar a criação da viagem?}
    confirmEdit {Confirma as edições feitas na viagem?}
    cancelEdit {Tem certeza de que  deseja sair?}
    confirmRemoveDelivery {Deseja remover o pedido da viagem?}
    confirmUndoTrip {Deseja desfazer toda a viagem?}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.header.closeModalButton.label]:
    'Fechar modal',

  [tripsIntlIds.onTripActionModal.body.content]: `{type, select,
    confirmCreate {Depois de confirmar, a viagem será criada.\n\nSe não houver um entregador atribuído, você precisará atribuis manualmente quando houverem entregadores disponíveis.}
    cancelCreate {Ao cancelar, todas as alterações serão perdidas e você precisará refazê-las desde o início.}
    confirmEdit {Estas alterações serão permanentes.Aconselhamos que revise antes de confirmar.}
    cancelEdit {Suas alterações não foram salvas e você precisará repetí-las desde o início.}
    confirmRemoveDelivery {Assim que estiverem disponíveis para entrega, os pedidos removidos serão ofertados automaticamente aos Riders.}
    confirmUndoTrip {Após desfazer a viagem, os pedidos desta viagem serão removidos e cada um separadamente será ofertado automaticamente às pessoas Riders quando estiverem disponíveis para entrega.\n\nAconselhamos que revise antes de confirmar.}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onSubmitButton.label]: `{type, select,
    confirmCreate {Criar viagem}
    cancelCreate {Cancelar criação}
    confirmEdit {Confirmar edição}
    cancelEdit {Ficar e continuar}
    confirmRemoveDelivery {Remover pedido}
    confirmUndoTrip {Desfazer viagem}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onCancelButton.label]: `{type, select,
    confirmCreate {Cancelar}
    cancelCreate {Voltar}
    confirmEdit {Cancelar}
    cancelEdit {Sair}
    confirmRemoveDelivery {Voltar}
    confirmUndoTrip {Cancelar}
    other {}}
  `,

  [tripsIntlIds.tripPlanningDetails.hubAlias.label]:
    'Hub: <highlight>{hubAlias}</highlight>',
  [tripsIntlIds.tripPlanningDetails.plannedTo.label]:
    'Data da viagem: <highlight>{plannedTo}</highlight>',
  [tripsIntlIds.tripPlanningDetails.status.label]: 'Status da viagem:',
};
