import { manualOrAutomaticRiderAssignmentIntlIds } from './manual-or-automatic-rider-assignment.ids';

export const manualOrAutomaticRiderAssignmentLangEnUs = {
  [manualOrAutomaticRiderAssignmentIntlIds.mode
    .manualOrAutomatic]: `{trip_mode, select,
      manual {Manual}
      automatic {Automatic}
      other {}
    }`,

  [manualOrAutomaticRiderAssignmentIntlIds.status.noRiderAssigned]:
    'No Rider assigned',
  [manualOrAutomaticRiderAssignmentIntlIds.status.findingRider]: 'Offering',
  [manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned]:
    '{rider_name}',
};
