import { DrawerFooter as ChakraDrawerFooter } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerFooterProps } from './drawer-footer.types';

export default forwardRef(function DrawerFooter(
  props: DrawerFooterProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraDrawerFooter {...props} ref={ref} />;
});
