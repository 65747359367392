export const shiftsIntlIds = {
  seo: {
    pages: {
      shiftsList: {
        title: 'shiftsIntlIds.seo.pages.shiftsList.title',
        description: 'shiftsIntlIds.seo.pages.shiftsList.description',
      },
    },
  },

  shiftsList: {
    header: {
      title: 'shiftsIntlIds.shiftsList.header.title',
      createButton: {
        label: 'shiftsIntlIds.shiftsList.header.createButton.label',
      },

      tabs: {
        all: {
          label: 'shiftsIntlIds.shiftsList.header.tabs.all.label',
        },
      },
    },

    table: {
      cell: {
        header: {
          id: 'shiftsIntlIds.shiftsList.table.cell.header.id',
          name: 'shiftsIntlIds.shiftsList.table.cell.header.name',
          startTime: 'shiftsIntlIds.shiftsList.table.cell.header.startTime',
          endTime: 'shiftsIntlIds.shiftsList.table.cell.header.endTime',
          duration: 'shiftsIntlIds.shiftsList.table.cell.header.duration',
          createdAt: 'shiftsIntlIds.shiftsList.table.cell.header.createdAt',
        },
      },

      noShiftsFound: {
        label: 'shiftsIntlIds.shiftsList.noShiftsFound.label',
      },
    },

    toasts: {
      onSuccess: {
        title: 'shiftsIntlIds.shiftsList.toasts.onSuccess.title',
        description: 'shiftsIntlIds.shiftsList.toasts.onSuccess.description',
      },
    },
  },

  createShift: {
    drawer: {
      title: 'shiftsIntlIds.createShift.drawer.title',
      closeDrawerButton: {
        label: 'shiftsIntlIds.createShift.drawer.closeDrawerButton.label',
      },
      content: {
        header: {
          title: 'shiftsIntlIds.createShift.drawer.content.header.title',
          description:
            'shiftsIntlIds.createShift.drawer.content.header.description',
        },
        shiftCharacteristics: {
          icon: {
            title:
              'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.icon.title',
          },
          title:
            'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.title',
          description:
            'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.description',
          shiftName: {
            input: {
              label:
                'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input.label',
              placeholder:
                'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input.placeholder',
              errors: {
                nameAlreadyInUse:
                  'shiftsIntlIds.createShift.drawer.content.shiftCharacteristics.shiftName.input.errors.nameAlreadyInUse',
              },
            },
          },
        },
        shiftPeriod: {
          icon: {
            title:
              'shiftsIntlIds.createShift.drawer.content.shiftPeriod.icon.title',
          },
          title: 'shiftsIntlIds.createShift.drawer.content.shiftPeriod.title',
          description:
            'shiftsIntlIds.createShift.drawer.content.shiftPeriod.description',
          shiftStartTime: {
            input: {
              label:
                'shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftStartTime.input.label',
            },
          },
          shiftEndTime: {
            input: {
              label:
                'shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input.label',
              errors: {
                invalidPeriod:
                  'shiftsIntlIds.createShift.drawer.content.shiftPeriod.shiftEndTime.input.errors.invalidPeriod',
              },
            },
          },
        },
        footer: {
          confirmCreateShiftButton: {
            label:
              'shiftsIntlIds.createShift.content.footer.confirmCreateShiftButton.label',
          },
          cancelCreateShiftButton: {
            label:
              'shiftsIntlIds.createShift.content.footer.cancelCreateShiftButton.label',
          },
        },
      },
    },
  },
};
