import { tripsIntlIds } from './trips.ids';

export const tripsLangEnUs = {
  [tripsIntlIds.seo.pages.tripsList.title]: 'Trips list',
  [tripsIntlIds.seo.pages.tripsList.description]:
    'Web application for Rider management and last mile Deliveries',

  [tripsIntlIds.seo.pages.tripChooseDeliveries.title]: 'Create Trip',
  [tripsIntlIds.seo.pages.tripChooseDeliveries.description]:
    'Web application for Rider management and last mile Deliveries',

  [tripsIntlIds.seo.pages.tripCreate.title]: 'Create Trip',
  [tripsIntlIds.seo.pages.tripCreate.description]:
    'Web application for Rider management and last mile Deliveries',

  [tripsIntlIds.seo.pages.tripEdit.title]: 'Edit Trip',
  [tripsIntlIds.seo.pages.tripEdit.description]:
    'Web application for Rider management and last mile Deliveries',

  [tripsIntlIds.seo.pages.tripDetails.title]: 'Trip {tripId}',
  [tripsIntlIds.seo.pages.tripDetails.description]:
    'Web application for Rider management and last mile Deliveries',

  [tripsIntlIds.tripsList.header.title]: 'Trips',
  [tripsIntlIds.tripsList.header.createButton.label]: 'Create trip',

  [tripsIntlIds.tripContentHeader.navigationInfo.trips]: 'Trips',

  [tripsIntlIds.tripStatus.pending]: 'Pending',
  [tripsIntlIds.tripStatus.started]: 'Started',
  [tripsIntlIds.tripStatus.completed]: 'Completed',
  [tripsIntlIds.tripStatus.canceled]: 'Canceled',
  [tripsIntlIds.noTripsFound.label]: `There are no trips {trip_status, select,
      pending {yet to start}
      started {started}
      completed {completed}
      canceled {canceled}
      voided {}
      unknown {}
      other {created}
  }`,

  [tripsIntlIds.tripsList.table.cell.header.tripId]: 'Trip ID',
  [tripsIntlIds.tripsList.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.tripsList.table.cell.header.deliveryDate]: 'Delivery date',
  [tripsIntlIds.tripsList.table.cell.header.numberOfDeliveries]:
    'Number of deliveries',
  [tripsIntlIds.tripsList.table.cell.header.tripStatus]: 'Trip status',
  [tripsIntlIds.tripsList.table.cell.header.assignedRider]: 'Assigned rider',

  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries
    .total]: `{total, plural,
    =0 {# deliveries}
    one {# delivery}
    other {# deliveries}
  }`,
  [tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries.byStatus]:
    '{total_processing} Processing, {total_ready} Ready and {total_canceled} Canceled',

  [tripsIntlIds.tripsList.header.tabs.all.label]: 'All',
  [tripsIntlIds.tripsList.header.tabs.pending.label]: 'Pending',
  [tripsIntlIds.tripsList.header.tabs.started.label]: 'Started',
  [tripsIntlIds.tripsList.header.tabs.completed.label]: 'Completed',
  [tripsIntlIds.tripsList.header.tabs.canceled.label]: 'Canceled',

  [tripsIntlIds.tripsList.toasts.onSuccess.title]: 'Trip created successfully!',
  [tripsIntlIds.tripsList.toasts.onSuccess.description]:
    'Check the details in the trips list',

  [tripsIntlIds.tripsList.toasts.onWarning.title]: 'Trip {tripId} undone',
  [tripsIntlIds.tripsList.toasts.onWarning.description]:
    'Deliveries will be offered separately automatically',

  [tripsIntlIds.createTrip.selectHubAndDateDrawer.title]: 'Create trip',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.title]:
    'Select the hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .description]: 'Select the hub for which the trip will be created',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.label]:
    'Hub',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .placeholder]: 'E.g: SAO001 - Pinheiros',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub
    .noScheduledOrders]:
    'There are no scheduled deliveries for this hub, choose another or try again later',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.title]:
    'Select the delivery date',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .description]:
    'All orders scheduled for delivery on the selected date will be made available for the trip',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.label]:
    'Delivery Date',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate
    .placeholder]: 'E.g: Monday, 06/20',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer
    .chooseDeliveriesButton.label]: 'Choose orders',
  [tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer.cancelButton
    .label]: 'Cancel',

  [tripsIntlIds.createTrip.header.title]: 'Create new trip: {hubAlias}',
  [tripsIntlIds.createTrip.header.navigationInfo.label]:
    'Hub selection / Order selection / Confirmation',

  [tripsIntlIds.createTrip.header.confirmCreateButton.label]: 'Create trip',
  [tripsIntlIds.createTrip.header.cancelCreateButton.label]: 'Cancel',

  [tripsIntlIds.editTrip.header.title]: 'Trip: {tripId}',
  [tripsIntlIds.editTrip.header.navigationInfo.label]:
    'Trip details / Edit trip',
  [tripsIntlIds.editTrip.header.updateChosenDeliveriesButton
    .label]: `Update {deliveriesQuantity, plural,
        =0 {orders}
        one {# order}
        other {# orders}
      }`,
  [tripsIntlIds.editTrip.header.cancelUpdateChosenDeliveriesButton.label]:
    'Cancel',
  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirm edit',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancel edit',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Undo trip',

  [tripsIntlIds.editTrip.tripAlreadyStarted.title]:
    'This trip has already started!',
  [tripsIntlIds.editTrip.tripAlreadyStarted.description]:
    'Since the trip has already started, all edits made cannot be saved.',
  [tripsIntlIds.editTrip.tripAlreadyStarted.gotItButton.label]: 'Got it',

  [tripsIntlIds.chooseDeliveries.header.title]: 'Create new trip: {hubAlias}',
  [tripsIntlIds.chooseDeliveries.header.navigationInfo.label]:
    'Hub selection / Order selection',
  [tripsIntlIds.chooseDeliveries.header.addsDeliveriesToTripButton
    .label]: `Add {deliveriesQuantity, plural,
      =0 {orders}
      one {# order}
      other {# orders}
    }`,
  [tripsIntlIds.chooseDeliveries.header.cancelAddDeliveriesToTripButton.label]:
    'Cancel',
  [tripsIntlIds.chooseDeliveries.table.cell.header.orderId]: 'Order ID',
  [tripsIntlIds.chooseDeliveries.table.cell.header.hubAlias]: 'Hub',
  [tripsIntlIds.chooseDeliveries.table.cell.header.addressFormatted]: 'Address',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryDate]:
    'Delivery date',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryEta]: 'Delivery ETA',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryPeriod]:
    'Delivery period',
  [tripsIntlIds.chooseDeliveries.table.cell.header.deliveryAssortmentType]:
    'Order type',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.copyAddress
    .label]: 'Copy address',
  [tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.addressCopied
    .label]: 'Address copied!',

  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.label]:
    'There are no scheduled orders at hub {hubAlias}',
  [tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.searchEmptyState
    .orderId.label]:
    "Oops! We didn't find any Order ID at hub {hubAlias} with {orderId}",

  [tripsIntlIds.editTrip.header.confirmEditButton.label]: 'Confirm edit',
  [tripsIntlIds.editTrip.header.cancelEditButton.label]: 'Cancel edit',
  [tripsIntlIds.editTrip.content.undoButton.label]: 'Undo trip',

  [tripsIntlIds.tripDetails.header.title]: 'Trip: {tripId}',
  [tripsIntlIds.tripDetails.header.navigationInfo.label]: 'Trip details',
  [tripsIntlIds.tripDetails.header.editTripButton.label]: 'Edit trip',
  [tripsIntlIds.tripDetails.header.editTripButton.isDisabled.label]:
    'It is not possible to edit trips that have already started',

  [tripsIntlIds.tripDetails.chosenDelivery.orderId.label]: 'Order: {orderId}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryEta.label]:
    'Delivery period: {etaMin} - {etaMax}',
  [tripsIntlIds.tripDetails.chosenDelivery.deliveryStatus.label]: 'Status:',
  [tripsIntlIds.tripDetails.chosenDelivery.dragDeliveryButton.label]:
    'Drag delivery',
  [tripsIntlIds.tripDetails.chosenDelivery.removeDeliveryButton.label]:
    'Remove delivery',

  [tripsIntlIds.tripDetails.tripSummaryDetails.tripDate.label]: 'Trip Date',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity.label]:
    'Added orders',
  [tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity
    .value]: `{addedDeliveriesQuantity, plural,
      =0 {# orders}
      one {# order}
      other {# orders}
    }`,
  [tripsIntlIds.tripDetails.tripSummaryDetails.addsMoreDeliveriesToTripButton
    .label]: '+ Add more orders',
  [tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title]:
    'Trip edited successfully!',

  [tripsIntlIds.onTripActionModal.header.title]: `{type, select,
    confirmCreate {Confirm trip creation?}
    cancelCreate {Do you want to cancel the trip creation?}
    confirmEdit {Do you confirm the edits made to the trip?}
    cancelEdit {Are you sure of leaving?}
    confirmRemoveDelivery {Do you want to remove the order from the trip?}
    confirmUndoTrip {Do you want to undo the entire trip?}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.header.closeModalButton.label]:
    'Fechar modal',

  [tripsIntlIds.onTripActionModal.body.content]: `{type, select,
    confirmCreate {After confirming, the trip will be created.\n\nIf no delivery person is assigned, you will need to assign one manually when delivery persons are available.}
    cancelCreate {By canceling, all changes will be lost and you will need to redo them from the beginning.}
    confirmEdit {These changes will be permanent. We advise you to review them before confirming.}
    cancelEdit {Your changes were not saved and you will need to repeat them from the beginning.}
    confirmRemoveDelivery {Once they are available for delivery, the removed orders will be automatically offered to the Riders.}
    confirmUndoTrip {After undoing the trip, the orders from this trip will be removed and each one will be automatically offered separately to the Riders when they are available for delivery.\n\nWe advise you to review before confirming.}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onSubmitButton.label]: `{type, select,
    confirmCreate {Create trip}
    cancelCreate {Cancel creation}
    confirmEdit {Confirm edit}
    cancelEdit {Stay and continue}
    confirmRemoveDelivery {Remove order}
    confirmUndoTrip {Undo trip}
    other {}}
  `,

  [tripsIntlIds.onTripActionModal.footer.onCancelButton.label]: `{type, select,
    confirmCreate {Cancel}
    cancelCreate {Back}
    confirmEdit {Cancel}
    cancelEdit {Leave}
    confirmRemoveDelivery {Back}
    confirmUndoTrip {Cancel}
    other {}}
  `,

  [tripsIntlIds.tripPlanningDetails.hubAlias.label]:
    'Hub: <highlight>{hubAlias}</highlight>',
  [tripsIntlIds.tripPlanningDetails.plannedTo.label]:
    'Trip date: <highlight>{plannedTo}</highlight>',
  [tripsIntlIds.tripPlanningDetails.status.label]: 'Trip status:',
};
