import { dashboardLayoutIntlIds } from './dashboard-layout';

export const dashboardLayoutLangEnUs = {
  [dashboardLayoutIntlIds.sidebar.navigation.scope.planning.label]: 'Planning',

  [dashboardLayoutIntlIds.sidebar.navigation.items.deliveries.label]:
    'Deliveries: List',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.availability.label]:
    'Rider Availability',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.management.label]:
    'Rider: List',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.shifts.label]:
    'Rider: Shifts',
  [dashboardLayoutIntlIds.sidebar.navigation.items.trips.label]: 'Trips: List',
  [dashboardLayoutIntlIds.sidebar.navigation.items.hubs.label]: 'Hubs: List',

  [dashboardLayoutIntlIds.sidebar.profile.userName]: 'Unknown',
  [dashboardLayoutIntlIds.sidebar.profile.items.signOut]: 'Sign out',
};
