import { tripInfosIntlIds } from './trip-infos.ids';

export const tripInfosLangEnUs = {
  [tripInfosIntlIds.changeOrAssignTripRiderModal.delivery.title]:
    'New Rider: Delivery {deliveryId}',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.trip.title]:
    'New Rider: Trip {hubAlias}',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder]:
    'Select Rider',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.selectRider.option.noRider]:
    'No Rider',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.button.cancel.label]: 'Cancel',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.button.sendOffer.label]:
    'Send offer',
  [tripInfosIntlIds.changeOrAssignTripRiderModal.button.assign.label]: 'Assign',
  [tripInfosIntlIds.tripActionButton.label]:
    '{type, select, assign {Assign} change {Change} other {}} Rider',
  [tripInfosIntlIds.tripDetails.id.label]: 'TRIP: {tripId}',
  [tripInfosIntlIds.tripDetails.assignmentMode.label]:
    '{tripMode, select, manual {Manual} automatic {Automatic} other {}}:',
  [tripInfosIntlIds.tripDetails.assignmentMode.status.noRiderAssigned]:
    'No Rider assigned',
  [tripInfosIntlIds.tripDetails.assignmentMode.status.findingRider]: 'Offering',
  [tripInfosIntlIds.tripDetails.assignmentMode.status.assigned]: 'Assigned',
  [tripInfosIntlIds.tripRiderInfos.avatar.title]: 'Rider avatar',
  [tripInfosIntlIds.tripRiderInfos.hub]:
    'Hub: <highlight>{hubAlias}</highlight>',
  [tripInfosIntlIds.tripRiderInfos.modal]:
    'Modal: <highlight>Logistics Operator (LO)</highlight>',
};
