import { Drawer as ChakraDrawer } from '@chakra-ui/react';
import { ReactElement } from 'react';

import { DrawerProps } from './drawer.types';

export default function Drawer(props: DrawerProps): ReactElement {
  return (
    <ChakraDrawer {...props} returnFocusOnClose={false} variant="primary" />
  );
}
