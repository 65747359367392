export const manualOrAutomaticRiderAssignmentIntlIds = {
  mode: {
    manualOrAutomatic:
      'manualOrAutomaticRiderAssignmentIntlIds.mode.manualOrAutomatic',
  },

  status: {
    noRiderAssigned:
      'manualOrAutomaticRiderAssignmentIntlIds.status.noRiderAssigned',
    findingRider: 'manualOrAutomaticRiderAssignmentIntlIds.status.findingRider',
    riderAssigned:
      'manualOrAutomaticRiderAssignmentIntlIds.status.riderAssigned',
  },
};
