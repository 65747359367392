export const tripsIntlIds = {
  seo: {
    pages: {
      tripsList: {
        title: 'tripsIntlIds.seo.pages.tripsList.title',
        description: 'tripsIntlIds.seo.pages.tripsList.description',
      },

      tripChooseDeliveries: {
        title: 'tripsIntlIds.seo.pages.tripChooseDeliveries.title',
        description: 'tripsIntlIds.seo.pages.tripChooseDeliveries.description',
      },

      tripCreate: {
        title: 'tripsIntlIds.seo.pages.tripCreate.title',
        description: 'tripsIntlIds.seo.pages.tripCreate.description',
      },

      tripEdit: {
        title: 'tripsIntlIds.seo.pages.tripEdit.title',
        description: 'tripsIntlIds.seo.pages.tripEdit.description',
      },

      tripDetails: {
        title: 'tripsIntlIds.seo.pages.tripDetails.title',
        description: 'tripsIntlIds.seo.pages.tripDetails.description',
      },
    },
  },

  tripContentHeader: {
    navigationInfo: {
      trips: 'tripsIntlIds.tripContentHeader.navigationInfo.trip',
    },
  },

  tripStatus: {
    pending: 'tripsIntlIds.tripStatus.pending',
    started: 'tripsIntlIds.tripStatus.started',
    completed: 'tripsIntlIds.tripStatus.completed',
    canceled: 'tripsIntlIds.tripStatus.canceled',
  },

  noTripsFound: {
    label: 'tripsIntlIds.noTripsFound.label',
  },

  tripsList: {
    header: {
      title: 'tripsIntlIds.tripsList.header.title',
      createButton: {
        label: 'tripsIntlIds.tripsList.header.createButton.label',
      },

      tabs: {
        all: {
          label: 'tripsIntlIds.tripsList.header.tabs.all.label',
        },
        pending: {
          label: 'tripsIntlIds.tripsList.header.tabs.pending.label',
        },
        started: {
          label: 'tripsIntlIds.tripsList.header.tabs.started.label',
        },
        completed: {
          label: 'tripsIntlIds.tripsList.header.tabs.completed.label',
        },
        canceled: {
          label: 'tripsIntlIds.tripsList.header.tabs.canceled.label',
        },
      },
    },

    table: {
      cell: {
        header: {
          tripId: 'tripsIntlIds.tripsList.table.cell.header.tripId',
          hubAlias: 'tripsIntlIds.tripsList.table.cell.header.hubAlias',
          deliveryDate: 'tripsIntlIds.tripsList.table.cell.header.deliveryDate',
          numberOfDeliveries:
            'tripsIntlIds.tripsList.table.cell.header.numberOfDeliveries',
          tripStatus: 'tripsIntlIds.tripsList.table.cell.header.tripStatus',
          assignedRider:
            'tripsIntlIds.tripsList.table.cell.header.assignedRider',
        },

        body: {
          numberOfDeliveries: {
            total:
              'tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries.total',
            byStatus:
              'tripsIntlIds.tripsList.table.cell.body.numberOfDeliveries.byStatus',
          },
        },
      },
    },

    toasts: {
      onSuccess: {
        title: 'tripsIntlIds.tripsList.toasts.onSuccess.title',
        description: 'tripsIntlIds.tripsList.toasts.onSuccess.description',
      },

      onWarning: {
        title: 'tripsIntlIds.tripsList.toasts.onWarning.title',
        description: 'tripsIntlIds.tripsList.toasts.onWarning.description',
      },
    },
  },

  chooseDeliveries: {
    header: {
      title: 'tripsIntlIds.chooseDeliveries.header.title',
      navigationInfo: {
        label: 'tripsIntlIds.chooseDeliveries.header.navigationInfo.label',
      },

      addsDeliveriesToTripButton: {
        label:
          'tripsIntlIds.chooseDeliveries.header.addsDeliveriesToTripButton.label',
      },
      cancelAddDeliveriesToTripButton: {
        label:
          'tripsIntlIds.chooseDeliveries.header.cancelAddDeliveriesToTripButton.label',
      },
    },

    table: {
      cell: {
        header: {
          orderId: 'tripsIntlIds.chooseDeliveries.table.cell.header.orderId',
          hubAlias: 'tripsIntlIds.chooseDeliveries.table.cell.header.hubAlias',
          addressFormatted:
            'tripsIntlIds.chooseDeliveries.table.cell.header.addressFormatted',
          deliveryDate:
            'tripsIntlIds.chooseDeliveries.table.cell.header.deliveryDate',
          deliveryEta:
            'tripsIntlIds.chooseDeliveries.table.cell.header.deliveryEta',
          deliveryPeriod:
            'tripsIntlIds.chooseDeliveries.table.cell.header.deliveryPeriod',
          deliveryAssortmentType:
            'tripsIntlIds.chooseDeliveries.table.cell.header.deliveryAssortmentType',
        },

        body: {
          addressFormatted: {
            copyAddress: {
              label:
                'tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.copyAddress.label',
            },
            addressCopied: {
              label:
                'tripsIntlIds.chooseDeliveries.table.cell.body.addressFormatted.addressCopied.label',
            },
          },
        },
      },
    },

    noDeliveriesFoundForChosenHub: {
      label:
        'tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.label',

      searchEmptyState: {
        orderId: {
          label:
            'tripsIntlIds.chooseDeliveries.noDeliveriesFoundForChosenHub.searchEmptyState.orderId.label',
        },
      },
    },
  },

  createTrip: {
    header: {
      title: 'tripsIntlIds.createTrip.header.title',
      navigationInfo: {
        label: 'tripsIntlIds.createTrip.header.navigationInfo.label',
      },

      confirmCreateButton: {
        label: 'tripsIntlIds.createTrip.header.confirmCreateButton.label',
      },
      cancelCreateButton: {
        label: 'tripsIntlIds.createTrip.header.cancelCreateButton.label',
      },
    },
    selectHubAndDateDrawer: {
      title: 'tripsIntlIds.createTrip.selectHubAndDateDrawer.title',

      content: {
        selectHub: {
          title:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.title',
          description:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.description',

          label:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.label',
          placeholder:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.placeholder',

          noScheduledOrders:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectHub.noScheduledOrders',
        },

        selectDate: {
          title:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.title',
          description:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.description',
          label:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.label',
          placeholder:
            'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.selectDate.placeholder',
        },

        footer: {
          chooseDeliveriesButton: {
            label:
              'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer.chooseDeliveriesButton.label',
          },
          cancelButton: {
            label:
              'tripsIntlIds.createTrip.selectHubAndDateDrawer.content.footer.cancelButton.label',
          },
        },
      },
    },
  },

  editTrip: {
    header: {
      title: 'tripsIntlIds.editTrip.header.title',
      navigationInfo: {
        label: 'tripsIntlIds.editTrip.header.navigationInfo.label',
      },

      updateChosenDeliveriesButton: {
        label:
          'tripsIntlIds.editTrip.header.updateChosenDeliveriesButton.label',
      },
      cancelUpdateChosenDeliveriesButton: {
        label:
          'tripsIntlIds.editTrip.header.cancelUpdateChosenDeliveriesButton.label',
      },
      confirmEditButton: {
        label: 'tripsIntlIds.editTrip.header.confirmEditButton.label',
      },
      cancelEditButton: {
        label: 'tripsIntlIds.editTrip.header.cancelEditButton.label',
      },
    },

    content: {
      undoButton: {
        label: 'tripsIntlIds.editTrip.content.undoButton.label',
      },
    },

    tripAlreadyStarted: {
      title: 'tripsIntlIds.editTrip.tripAlreadyStarted.title',
      description: 'tripsIntlIds.editTrip.tripAlreadyStarted.description',
      gotItButton: {
        label: 'tripsIntlIds.editTrip.tripAlreadyStarted.gotItButton.label',
      },
    },
  },

  tripDetails: {
    header: {
      title: 'tripsIntlIds.tripDetails.header.title',
      navigationInfo: {
        label: 'tripsIntlIds.tripDetails.header.navigationInfo.label',
      },

      editTripButton: {
        label: 'tripsIntlIds.tripDetails.header.editTripButton.label',

        isDisabled: {
          label:
            'tripsIntlIds.tripDetails.header.editTripButton.isDisabled.label',
        },
      },
    },

    tripSummaryDetails: {
      tripDate: {
        label: 'tripsIntlIds.tripDetails.tripSummaryDetails.tripDate.label',
      },
      addedDeliveriesQuantity: {
        label:
          'tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity.label',
        value:
          'tripsIntlIds.tripDetails.tripSummaryDetails.addedDeliveriesQuantity.value',
      },
      addsMoreDeliveriesToTripButton: {
        label: 'tripsIntlIds.tripDetails.addsMoreDeliveriesToTripButton.label',
      },
    },

    chosenDelivery: {
      orderId: {
        label: 'tripsIntlIds.tripDetails.chosenDelivery.orderId.label',
      },
      deliveryEta: {
        label: 'tripsIntlIds.tripDetails.chosenDelivery.deliveryEta.label',
      },
      deliveryStatus: {
        label: 'tripsIntlIds.tripDetails.chosenDelivery.deliveryStatus.label',
      },
      dragDeliveryButton: {
        label:
          'tripsIntlIds.tripDetails.chosenDelivery.dragDeliveryButton.label',
      },
      removeDeliveryButton: {
        label:
          'tripsIntlIds.tripDetails.chosenDelivery.removeDeliveryButton.label',
      },
    },

    toasts: {
      onTripUpdatedSuccess: {
        title: 'tripsIntlIds.tripDetails.toasts.onTripUpdatedSuccess.title',
      },
    },
  },

  onTripActionModal: {
    header: {
      title: 'tripsIntlIds.onTripActionModal.header.title',
      closeModalButton: {
        label: 'tripsIntlIds.onTripActionModal.header.closeModalButton.label',
      },
    },

    body: {
      content: 'tripsIntlIds.onTripActionModal.body.content',
    },

    footer: {
      onSubmitButton: {
        label: 'tripsIntlIds.onTripActionModal.footer.onSubmitButton.label',
      },
      onCancelButton: {
        label: 'tripsIntlIds.onTripActionModal.footer.onCancelButton.label',
      },
    },
  },

  tripPlanningDetails: {
    hubAlias: {
      label: 'tripsIntlIds.tripPlanningDetails.hubAlias.label',
    },
    status: {
      label: 'tripsIntlIds.tripPlanningDetails.status.label',
    },
    plannedTo: {
      label: 'tripsIntlIds.tripPlanningDetails.label.label',
    },
  },
};
