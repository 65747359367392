export default function formatUserName(fullName: string) {
  const connectives = ['de', 'da', 'do', 'dos', 'das', 'e'];

  const parts = fullName.split(' ');

  if (parts.length === 1) return fullName;

  const firstName = parts[0];
  const lastName = parts[parts.length - 1];

  const initials = [];

  for (let i = 1; i < parts.length - 1; i++) {
    const part = parts[i];

    if (!connectives.includes(part.toLowerCase())) {
      initials.push(part.charAt(0).toUpperCase() + '.');
    }
  }

  if (initials.length) {
    return `${firstName} ${initials.join(' ')} ${lastName}`;
  } else {
    return `${firstName} ${lastName}`;
  }
}
