export const deliverySearchInputIntlIds = {
  type: {
    searchBy: 'deliverySearchInputIntlIds.type.searchBy',
    orderId: 'deliverySearchInputIntlIds.type.orderId',
    riderId: 'deliverySearchInputIntlIds.type.riderId',
  },

  input: {
    label: 'deliverySearchInputIntlIds.input.label',
    placeholder: 'deliverySearchInputIntlIds.input.placeholder',
  },

  submitButton: {
    label: 'deliverySearchInputIntlIds.submitButton.label',
  },
};
